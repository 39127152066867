/**
 * Form class.
 *
 * @since unreleased
 */
export default class Form {
  /**
   * Form constructor.
   *
   * @param {HTMLElement} form Form DOM element.
   * @since unreleased
   */
  constructor (form) {
    // If there is no form, bail.
    if (!form) return

    this.form = form
    this.action = form.getAttribute('action')
    this.fields = [...form.querySelectorAll('input, textarea')]
    this.buttons = [...form.querySelectorAll('button')]
    this.submitButton = form.querySelector('[data-form-action="submit"]')
    this.message = {
      success: form.dataset?.messageSuccess || null,
      error: form.dataset?.messageError || null,
    }

    // List for form submissions.
    form.addEventListener('submit', event => this.handleSubmit(event))
  }

  handleSubmit (event) {
    // Prevent form submission.
    event.preventDefault()

    // Submit form async.
    fetch(this.action || '/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(new FormData(this.form)).toString(),
    })
      .then(this.handleSuccess())
      .catch(error => this.handleError(error))
  }

  handleSuccess () {
    // Read-only fields.
    this.fields.forEach(field => {
      field.setAttribute('readonly', '')
    })

    // Disable buttons.
    this.buttons.forEach(button => {
      button.setAttribute('disabled', '')
    })

    // Display success message.
    this.displayMessage('success')
  }

  handleError (error) {
    // Log error.
    if (error) console.error('There was an error during form submission.', error)

    // Display error message.
    this.displayMessage('error')
  }

  /**
   * Display form message.
   *
   * @param {string} type The message type.
   * @since unreleased
   */
  displayMessage (type) {
    if (this.message[type]) {
      // If there is an old message, remove it.
      const old = this.form.querySelector('[data-form="message"]')
      if (old) old.parentElement.removeChild(old)

      // Add new message.
      const fragment = document.createDocumentFragment()
      const message = document.createElement('span')
      message.classList.add('form__message')
      message.classList.add(`form__message_${type}`)
      message.setAttribute('data-form', 'message')
      message.textContent = this.message[type]
      fragment.appendChild(message)
      this.submitButton.parentElement.append(fragment)
    }
  }
}
